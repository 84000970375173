import React from 'react';
import styled from 'styled-components';

const StyledParagraphContent = styled.div`
    > p {
        font-family: 'Open Sans';
        font-size: 1.5em;
    }
`;

const Paragraph = ({ description }) => {
    const formattedDescription = description
        .split('\n\n')
        .map(paragraph => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`)
        .join(``);
    return (
        <StyledParagraphContent
            dangerouslySetInnerHTML={{ __html: formattedDescription }}
        />
    );
};

export default Paragraph;
