import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { HeroContainer } from '../components/Containers';
import Paragraph from '../components/Paragraph';

export const IndexPageTemplate = ({
    image,
    title,
    sections,
    subheading,
    mainpitch
}) => (
    <div>
        <HeroContainer title={title} image={image} subheading={subheading} />
        <section className="section section--gradient">
            <div className="container">
                <div className="section">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="content">
                                <div className="tile">
                                    <h1 className="title">{mainpitch.title}</h1>
                                </div>
                                <div className="tile">
                                    <Paragraph
                                        description={mainpitch.description}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {sections.map(section => (
            <div key={section.heading}>
                <HeroContainer title={section.heading} image={section.image} />
                <section className="section section--gradient">
                    <div className="container">
                        <div className="section">
                            <div className="columns">
                                <div className="column is-10 is-offset-1">
                                    <div className="content">
                                        <div className="content">
                                            <div className="tile">
                                                <h3 className="subtitle">
                                                    {section.text}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-12 has-text-centered">
                                        <Link
                                            className="btn"
                                            to={section.sectionLink.link}
                                        >
                                            {section.sectionLink.linkText}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        ))}
    </div>
);

IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    mainpitch: PropTypes.object,
    sections: PropTypes.array
};

const IndexPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;

    return (
        <Layout>
            <SEO
                title={frontmatter.title}
                image={frontmatter.image.childImageSharp.fluid.src}
                description={frontmatter.description}
            />
            <IndexPageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                heading={frontmatter.heading}
                sections={frontmatter.sections}
                subheading={frontmatter.subheading}
                mainpitch={frontmatter.mainpitch}
            />
        </Layout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object
        })
    })
};

export default IndexPage;

export const pageQuery = graphql`
    query IndexPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                title
                description
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heading
                subheading
                sections {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    heading
                    text
                    sectionLink {
                        linkText
                        link
                    }
                }
                mainpitch {
                    title
                    description
                }
            }
        }
    }
`;
